



































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";
import { participantsService } from "@/services/participants.service";
import { UserStatus, YesNo } from "@/helpers";

@Component
export default class Participants extends Vue {
  metaInfo = {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Deelnemers",
  };

  rows: Array<any> = [];

  data(): any {
    return {
      columns: [
        {
          label: "Openstaand",
          field: "open",
          filterOptions: {
            enabled: true,
            filterDropdownItems: [
              "Geen aanvragen",
              "Open aanvragen",
              "Aanvragen afgehandeld",
            ],
            filterFn: this.userStatusFilter,
          },
        },
        {
          label: "Status",
          field: "status",
          filterOptions: {
            enabled: true,
            filterDropdownItems: UserStatus,
          },
        },
        {
          label: "Aanhef",
          field: "salutation",
          type: "String",
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "Voornaam",
          field: "firstName",
          width: "120px",
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: "Achternaam",
          field: "lastName",
          width: "180px",
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: "Adres",
          field: "address",
          width: "180px",
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: "Postcode",
          field: "zipcode",
          filterOptions: {
            enabled: true, // enable filter for this column
          },
        },
        {
          label: "Email",
          field: "email",
          width: "150",
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: "Nieuwsbrief",
          field: "newsLetter",
          filterOptions: {
            enabled: true,
            filterDropdownItems: ["Ja", "Nee"],
            filterFn: this.yesNoFilter,
          },
        },
        /*
        {
          label: '#Aanbod',
          field: 'hulpaanbieder',
          type: 'number',
          width: '100px',
        },
        {
          label: '#Vraag',
          field: 'hulpvragen',
          type: 'number',
          width: '100px',
        },
        */
        {
          label: "Inschrijfdatum",
          field: "registration",
          type: "Date",
          filterOptions: {
            enabled: true,
            filterDropdownItems: [
              "Afgelopen week",
              "Afgelopen maand",
              "Afgelopen jaar",
            ],
            filterFn: this.dateFilter,
          },
        },
        {
          label: "Aangemaakt door",
          field: "user",
          type: "string",
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: "Gewijzigd door",
          field: "userUpdates",
          type: "string",
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: "Laatst gewijzigd",
          field: "updated",
          type: "Date",
          filterOptions: {
            enabled: true,
            filterDropdownItems: [
              "Afgelopen week",
              "Afgelopen maand",
              "Afgelopen jaar",
            ],
            filterFn: this.dateFilter,
          },
        },
      ],
    };
  }

  mounted(): void {
    participantsService.getActive().then((participants) => {
      this.rows = participants.map((p: any) => ({
        id: p._id,
        lastName: p.lastName,
        firstName: p.firstName,
        status: p.status,
        hulpaanbieder: p.hulpaanbieder.length,
        hulpvragen: p.hulpvragen.length,
        open:
          p.hulpvragen.length > 0
            ? p.hulpvragen.filter((p: any) => p.status === "1").length
            : -1,
        user: p.user.length > 0 ? p.user[0].displayName : "",
        userUpdates:
          p.userUpdates.length > 0
            ? p.userUpdates[0].displayName
            : p.user.length > 0
            ? p.user[0].displayName
            : "",
        email: p.email,
        zipcode: p.zipcode,
        avg: p.avg || "no",
        mobileNumber: p.mobileNumber,
        address: `${p.address} ${p.houseNumber}`,
        updated: p.updated,
        newsLetter: p.newsLetter,
        salutation: p.salutation || "none",
        registration: p.registration || p.created || p.updated,
      }));
    });
  }

  onRowClick(params: any): void {
    this.$router.push(`/participants/${params.row.id}`);
  }

  onAdd(): void {
    this.$router.push("/participants/_new");
  }

  userStatusFilter(data: any, filterString: string): boolean {
    if (filterString === "Geen aanvragen") {
      return data === -1;
    }
    if (filterString === "Open aanvragen") {
      return data > 0;
    }
    if (filterString === "Aanvragen afgehandeld") {
      return data === 0;
    }
    return false;
  }

  dateFilter(data: any, filterString: string): boolean {
    const dt = moment(data);
    if (filterString === "Afgelopen week") {
      const weekDT = moment().subtract(7, "days");
      return dt.isSameOrAfter(weekDT);
    }
    if (filterString === "Afgelopen maand") {
      const weekDT = moment().subtract(1, "month");
      return dt.isSameOrAfter(weekDT);
    }
    if (filterString === "Afgelopen jaar") {
      const weekDT = moment().subtract(1, "year");
      return dt.isSameOrAfter(weekDT);
    }
    return false;
  }

  yesNoFilter(data: any, filterString: string): boolean {
    if (data === "yes" && filterString === "Ja") {
      return true;
    }
    if (data === "no" && filterString === "Nee") {
      return true;
    }

    return false;
  }
}
