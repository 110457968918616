import { render, staticRenderFns } from "./ParticipantsPage.vue?vue&type=template&id=4eca5e25&"
import script from "./ParticipantsPage.vue?vue&type=script&lang=ts&"
export * from "./ParticipantsPage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports